import * as React from 'react'
import Layout from '../components/layout'
import Card from '../components/card'

const NotFoundPage = () => {
  return (
    <Layout>
      <title>Not found</title>
      <Card>
        <h1>Page not found</h1>
        <p>
          URL does not seems right, for some reason. Maybe it expired? Maybe it
          has changed?
        </p>
        <p>
          If confused, you can{' '}
          <strong>
            <a
              href="mailto:kamalnrf@gmail.com"
              aria-label="my email"
              target="_blank"
              rel="noreferrer"
            >
              email me
            </a>
          </strong>
        </p>
      </Card>
    </Layout>
  )
}

export default NotFoundPage
