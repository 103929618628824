import styled from '@emotion/styled'
import '../vars.css'

const Card = styled.div`
  border: 1px solid var(--secondary-color);
  padding: 1rem;
  border-radius: 5px;
  color: var(--on-primary-color);
`

export default Card
